import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldView = _resolveComponent("FieldView")!
  const _component_PostView = _resolveComponent("PostView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FieldView),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (key) => {
      return (_openBlock(), _createBlock(_component_PostView, {
        key: key,
        id: key
      }, null, 8, ["id"]))
    }), 128))
  ]))
}